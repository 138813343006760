<template>
  <div class="search">
    <div class="search-main">
      <div class="search-main__title" data-aos="fade-up">
        <h1>Kết quả tìm kiếm: “{{ content }}”</h1>
      </div>
      <div class="search-main__content">
        <div class="list-search" v-if="data && data.length > 0">
          <div class="item" v-for="(item, index) in data" :key="index">
            <router-link :to="item.link">
              <h4 class="item__title" v-html="item[locale].title"></h4>
            </router-link>
            <h5 class="item__des"  v-html="item[locale].description"></h5>
          </div>
        </div>
        <p v-else>Không có kết quả</p>
      </div>
    </div>
  </div>
</template>
<script>
import SearchService from "@/services/SearchService";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      data: [],
    };
  },
  mounted() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.content = this.$route.query.search;
    this.getData();
  },
  computed: {
    ...mapGetters(["locale"]),
  },
  methods: {
    async getData() {
      await SearchService.search(this.content)
        .then((resp) => {
          this.data = resp.data.data;
        })
        .catch(() => {});
    },
  },
};
</script>
